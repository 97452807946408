export const CountriesStatusFilters = [
  {
    parentKey: "restrictedAt",
    title: "Country fields",
    filters: [
      {
        key: "residency",
        label: "Residency",
        isChecked: false,
      },
      {
        key: "nationality",
        label: "Nationality",
        isChecked: false,
      },
      {
        key: "cfdTrading",
        label: "CFD trading",
        isChecked: false,
      },
      {
        key: "cryptoCFDTrading",
        label: "Crypto CFD trading",
        isChecked: false,
      },
      {
        key: "leveragedCryptoCFDTrading",
        label: "Leveraged crypto CFD trading",
        isChecked: false,
      },
      {
        key: "tax",
        label: "Tax",
        isChecked: false,
      },
      {
        key: "withdrawal",
        label: "Withdrawal",
        isChecked: false,
      },
    ],
  },
  {
    parentKey: "restrictionType",
    title: "Status",
    filters: [
      {
        key: "SUPPORTED",
        label: "Supported",
        isChecked: false,
      },
      {
        key: "IOS_ONLY_RESTRICTED",
        label: "iOS-only restricted",
        isChecked: false,
      },
      {
        key: "RESTRICTED",
        label: "Restricted",
        isChecked: false,
      },
    ],
  },
];