/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, MouseEvent } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import CssFlexBox from 'components/CssFlexBox';
import ButtonGroup from 'components/ButtonGroup';
import { GlobalContext } from 'context';
import DWTransactions from './DWTransactions';
import WalletTransactions from './WalletTransactions';
import NIFundReceived from './NIFundReceived';
import NITransactions from './NITransactions';

const TransactionsHistory = () => {
  const {
    selectedButtonType,
    setSelectedButtonType,
    setIsFilterApplied,
    setIsFilterIconDisplay,
    setFilterDates,
    setTransactionsHistoryFilters,
    setIsFilterCleared,
  } = useContext(GlobalContext);
  

  const onReconButtonClick = (e: MouseEvent<HTMLDivElement>) => {
    const type = (e.target as HTMLDivElement).dataset.filter;
    setSelectedButtonType(type);

    // To clear applied filters
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    setTransactionsHistoryFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIsFilterCleared(true);
  };
  
  useEffect(() => {
    if (!selectedButtonType) {
      setSelectedButtonType('DW_TRANSACTIONS');
    }
  }, [selectedButtonType]);

  const getTransactionsView = () => {
    if (selectedButtonType === 'DW_TRANSACTIONS' ) {
      return <DWTransactions />
    } else if (selectedButtonType === 'WALLET_TRANSACTIONS') {
      return <WalletTransactions />
    }  else if (selectedButtonType === 'NI_FUND_RECEIVED') {
      return <NIFundReceived />
    } else if (selectedButtonType === 'NI_Transactions') {
      return <NITransactions />
    } else {
      return null;
    }
  };

  return (
    <CssContainer>
      <CssFlexBox sx={{justifyContent: 'space-between'}}>
        <CssTitle variant='h4' underline>Daily Reconciliation</CssTitle>
      </CssFlexBox>
      <Box sx={{display: 'flex', justifyContent: 'flex-start', mb: 2}}>
        <ButtonGroup variant='light' data={[
          {
            label: 'DW transactions',
            type: 'DW_TRANSACTIONS',
            onClick: onReconButtonClick
          },
          {
            label: 'Wallet transactions',
            type: 'WALLET_TRANSACTIONS',
            onClick: onReconButtonClick
          },
          {
            label: 'NI fund received',
            type: 'NI_FUND_RECEIVED',
            onClick: onReconButtonClick
          },
          {
            label: 'NI transactions',
            type: 'NI_Transactions',
            onClick: onReconButtonClick
          }
        ]} />
      </Box>
      {getTransactionsView()}
    </CssContainer>
  );
};

export default TransactionsHistory;