import useAxios from './axios-hook';
// import '__mocks__';

const useRequests = () => {
  const { fredAxiosInstance, moneyAxiosInstance, reportsAxiosInstance, onboardingAxiosInstance, masterAxiosInstance } = useAxios({ config: {} });

  const getClientsApi = (
    page: number,
    limit: number,
    q: string,
    fromDate: string,
    toDate: string,
    sort: number,
    sortBy: string,
    filterQuery: string
  ) => {
    let url = `/fred-service/clients?page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return fredAxiosInstance.get(url);
  };

  const getClientsDownloadApi = (
    q: string,
    fromDate: string,
    toDate: string,
    filterQuery: string
  ) => {
    let url = `/fred-service/clients/download`;

    if (q) {
      url += `&q=${q}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    url = url.replace(/&/, '?');

    return fredAxiosInstance.get(url);
  };

  const getCountriesRestrictionApi = (
    page: number,
    limit: number,
    q: string,
    sort: number,
    sortBy: string,
    filterQuery: string
  ) => {
    let url = `/fred-service/country-restrictions?page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`;
    }

    return fredAxiosInstance.get(url);
  };

  const getCountriesDownloadApi = (
    q: string,
    filterQuery: string
  ) => {
    let url = `/fred-service/country-restrictions/download`;

    if (q) {
      url += `&q=${q}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`;
    }

    url = url.replace(/&/, "?");

    return fredAxiosInstance.get(url);
  };

  const updateFeatureByCountryApi = (
    countryRestrictionId: string | number,
    params: any
  ) => {
    let url = `/fred-service/country-restrictions/${countryRestrictionId}`;
    return fredAxiosInstance.put(url, params);
  };

  const getDashboardClientsApi = (
    page: number,
    limit: number,
    q: string,
    sort: number,
    sortBy: string,
    groupName: string
  ) => {
    let url = `/fred-service/clients?page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (groupName) {
      url += `&groupName=${groupName}`
    }

    return fredAxiosInstance.get(url);
  };

  const updateKycStatusApi = (
    kycApplicantId: string | number,
    params: any
  ) => {
    let url = `/onboarding/kyc/${kycApplicantId}/status`;

    return onboardingAxiosInstance.put(url, params);
  };

  const getClientProfileApi = (
    clientId: string | number
  ) => {
    return fredAxiosInstance.get(`/fred-service/clients/${clientId}/personal-info`);
  };

  const updateClientProfileApi = (
    clientId: string | number,
    params: any
  ) => {
    return fredAxiosInstance.put(`/fred-service/clients/${clientId}`, params);
  };

  const getClientKycStatusApi = (
    clientId: string | number
  ) => {
    return fredAxiosInstance.get(`/fred-service/clients/${clientId}/kyc-status`);
  };

  const getClientAccountDetailsApi = (
    clientId: string | number
  ) => {
    return fredAxiosInstance.get(`/fred-service/clients/${clientId}/account-details`);
  };

  const getClientAccountDetailsV2Api = (
    clientId: string | number
  ) => {
    return fredAxiosInstance.get(`/fred-service/clients/${clientId}/v2-account-details`);
  };

  const getClientCashBalanceApi = (
    clientId: string | number
  ) => {
    return fredAxiosInstance.get(`/fred-service/clients/${clientId}/cash-balance`);
  };

  const getClientOrdersApi = (
    clientId: string | number,
    type: string,
    page: number,
    limit: number,
    fromDate: string,
    toDate: string,
    filterQuery: string
  ) => {
    let url = `/fred-service/clients/${clientId}/orders?type=${type}&page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&entryStartDate=${fromDate}`;
    }
    if (toDate) {
      url += `&entryEndDate=${toDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return fredAxiosInstance.get(url);
  };

  const getClientCashAdjustmentsApi = (
    clientId: string | number,
    page: number,
    limit: number,
    fromDate: string,
    toDate: string
  ) => {
    let url = `/fred-service/clients/${clientId}/transaction-history?page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    return fredAxiosInstance.get(url);
  };

  const getHolidayListByExchangeApi = (
    exchangeId: string | number
  ) => {
    return fredAxiosInstance.get(`/fred-service/exchange-holidays/${exchangeId}`);
  };

  const postHolidayForExchangeApi = (
    params: any
  ) => {
    return fredAxiosInstance.post(`/fred-service/exchange-holidays/insert`, params);
  };

  const updateHolidayForExchangeApi = (
    holidayId: string | number,
    params: any
  ) => {
    return fredAxiosInstance.put(`/fred-service/exchange-holidays/${holidayId}/update`, params);
  };

  const deleteHolidayForExchangeApi = (
    holidayId: string | number
  ) => {
    return fredAxiosInstance.delete(`/fred-service/exchange-holidays/${holidayId}/delete`);
  };

  const getInstrumentsApi = (
    page: number,
    limit: number,
    q: string,
    filterQuery: string
  ) => {
    let url = `/fred-service/stocks-list?page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return fredAxiosInstance.get(url);
  };

  const getStockFiltersApi = () => {
    let url = `/fred-service/stocks-filters`;

    return fredAxiosInstance.get(url);
  };

  const getOrdersApi = (
    type: string,
    page: number,
    limit: number,
    fromDate: string,
    toDate: string,
    filterQuery: string,
    q: string
  ) => {
    let url = `/fred-service/order-history?type=${type}&page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (fromDate) {
      url += `&entryStartDate=${fromDate}`;
    }
    if (toDate) {
      url += `&entryEndDate=${toDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return fredAxiosInstance.get(url);
  };

  const getTransactionsApi = (
    type: string,
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string,
    q: string
  ) => {
    let url = `/money-management/transactions?type=${type}&page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return moneyAxiosInstance.get(url);
  };

  const getTransactionsDownloadApi = (
    type: string,
    q: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string
  ) => {
    let url = `/money-management/transactions/download?type=${type}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return moneyAxiosInstance.get(url);
  };

  const getGiftsApi = (
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string,
    q: string
  ) => {
    let url = `/money-management/rewards?page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return moneyAxiosInstance.get(url);
  };

  const getFinOpsGiftsApi = (
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string,
    q: string
  ) => {
    let url = `/money-management/finops/rewards?page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return moneyAxiosInstance.get(url);
  };

  const getGiftsByClientApi = (
    clientId: string | number,
    page: number,
    limit: number,
    sort: number,
    sortBy: string
  ) => {
    let url = `/money-management/rewards?page=${page}&limit=${limit}`;

    if (clientId) {
      url += `&customerId=${clientId}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    return moneyAxiosInstance.get(url);
  };

  const getGiftsDownloadApi = (
    q: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string
  ) => {
    let url = `/money-management/rewards/download`;

    if (q) {
      url += `&q=${q}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    url = url.replace(/&/, '?');

    return moneyAxiosInstance.get(url);
  };

  const updateGiftsStatusApi = (
    params: any
  ) => {
    let url = `/money-management/rewards/status`;

    return moneyAxiosInstance.put(url, params);
  };

  const getTransactionsByClientIdApi = (
    clientId: string | number,
    type: string,
    page: number,
    limit: number,
    sort: number,
    sortBy: string
  ) => {
    let url = `/money-management/transactions?type=${type}&page=${page}&limit=${limit}`;

    if (clientId) {
      url += `&customerId=${clientId}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    return moneyAxiosInstance.get(url);
  };

  const getFinOpsTransactionsApi = (
    type: string,
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string,
    q: string
  ) => {
    let url = `/money-management/finops/transactions?type=${type}&page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return moneyAxiosInstance.get(url);
  };

  const updateTransactionsStatusApi = (
    params: any
  ) => {
    let url = `/money-management/withdrawals/status`;

    return moneyAxiosInstance.put(url, params);
  };

  const updateSingleTransactionStatusApi = (
    id: string | number,
    params: any
  ) => {
    let url = `/money-management/transactions/${id}/status`;

    return moneyAxiosInstance.put(url, params);
  };

  const getTransactionsCountByBatchIdApi = (
    batchNo: string
  ) => {
    let url = `/money-management/${batchNo}/transactionsCount`;

    return moneyAxiosInstance.get(url);
  };

  const getUpdateInprogressTxnsApi = () => {
    let url = `/money-management/withdrawals/updateInprogressTxns`;

    return moneyAxiosInstance.get(url);
  };

  const getWithdrawalReportsApi = (
    page: number,
    limit: number
  ) => {
    let url = `/money-management/withdrawals/reports?page=${page}&limit=${limit}`;

    return moneyAxiosInstance.get(url);
  };

  const getInstrumentDetailsApi = (
    instrumentId: string
  ) => {
    let url = `/fred-service/stocks-details/${instrumentId}`;

    return fredAxiosInstance.get(url);
  };

  const updateInstrumentDetailsApi = (
    instrumentId: string,
    params: any
  ) => {
    let url = `/fred-service/updateStock/${instrumentId}`;

    return fredAxiosInstance.put(url, params);
  };

  const updateCfdInstrumentDetailsApi = (
    instrumentId: string,
    params: any
  ) => {
    let url = `/fred-service/cfdUpdateStock/${instrumentId}`;

    return fredAxiosInstance.put(url, params);
  };

  const getRefinitivInfoApi = (
    symbol: string
  ) => {
    let url = `/fred-service/refinitiveinfo?symbol=${symbol}`;

    return fredAxiosInstance.get(url);
  };

  const getReportsApi = (
    type: string,
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    filterQuery: string,
    q: string
  ) => {
    let url = `/reporting/reports?type=${type}&page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    if (q) {
      url += `&q=${q}`;
    }

    return reportsAxiosInstance.get(url);
  };

  const getReportDownloadApi = (
    reportId: number
  ) => {
    let url = `/reporting/reports/${reportId}/download`;

    return reportsAxiosInstance.get(url);
  };

  const getWalletReportsApi = (
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    q: string
  ) => {
    let url = `/money-management/wallet/txn-history?page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (q) {
      url += `&q=${q}`;
    }

    return moneyAxiosInstance.get(url);
  };

  const getWalletSnapshotApi = (
    page: number,
    limit: number,
    fromDate: string,
    toDate: string,
    q: string
  ) => {
    let url = `/money-management/wallet/snapshot?page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (q) {
      url += `&q=${q}`;
    }

    return moneyAxiosInstance.get(url);
  };

  const getWalletSnapshotDownloadApi = (
    q: string,
    fromDate: string,
    toDate: string,
  ) => {
    let url = `/money-management/wallet/snapshot/download`;

    if (q) {
      url += `&q=${q}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    url = url.replace(/&/, '?');

    return moneyAxiosInstance.get(url);
  };

  const getWalletReportDownloadApi = (
    q: string,
    fromDate: string,
    toDate: string,
  ) => {
    let url = `/money-management/wallet/txn-history/download`;

    if (q) {
      url += `&q=${q}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    url = url.replace(/&/, '?');

    return moneyAxiosInstance.get(url);
  };

  const getReportsListApi = (
    type: string,
    page: number,
    limit: number
  ) => {
    let url = `/reporting/reports?type=${type}&page=${page}&limit=${limit}`;

    return reportsAxiosInstance.get(url);
  };

  const getSettlementsApi = (
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    filterQuery: string,
    source: string,
    q: string
  ) => {
    let url = `/reporting/dw-settlements?page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    if (source) {
      url += `&source=${source}`;
    }

    if (q) {
      url += `&q=${q}`;
    }

    return reportsAxiosInstance.get(url);
  };

  const updateSettlementsByIdApi = (
    id: number | string,
    params: any
  ) => {
    let url = `/reporting/dw-settlements/${id}`;

    return reportsAxiosInstance.put(url, params);
  };

  const updateNIFundSettlementsByIdApi = (id: number | string, params: any) => {
    let url = `/money-management/finops/ni-daily-summary/${id}`;

    return reportsAxiosInstance.put(url, params);
  };

  const getSettlementsListApi = (
    page: number,
    limit: number,
    source: string
  ) => {
    let url = `/reporting/dw-settlements?page=${page}&limit=${limit}`;

    if (source) {
      url += `&source=${source}`;
    }

    return reportsAxiosInstance.get(url);
  };

  const getSettlementsDownloadApi = (
    settlementId: number
  ) => {
    let url = `/reporting/settlements/${settlementId}/download`;

    return reportsAxiosInstance.get(url);
  };

  const updateSettlementStatusByIdApi = (
    settlementId: number,
    params: any
  ) => {
    let url = `/reporting/settlements/${settlementId}/status`;

    return reportsAxiosInstance.put(url, params);
  };

  const updateWTSettlementStatusByIdApi = (
    settlementId: number,
    params: any
  ) => {
    let url = `/money-management/finops/wallet-summary/${settlementId}/status`;

    return reportsAxiosInstance.put(url, params);
  };

  const updateNIFundSettlementStatusByIdApi = (
    settlementId: number,
    params: any
  ) => {
    let url = `/money-management/finops/ni-daily-summary/${settlementId}/status`;

    return reportsAxiosInstance.put(url, params);
  };
  
  const getTransactionHistoryListApi = (
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    filterQuery: string
  ) => {
    let url = `/reporting/settlements?page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return reportsAxiosInstance.get(url);
  };

  const getDWTransactionsApi = (
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    filterQuery: string
  ) => {
    let url = `/reporting/dw-settlements?page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return reportsAxiosInstance.get(url);
  };

  const getWalletTransactionsApi = (
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    filterQuery: string
  ) => {
    let url = `/money-management/finops/wallet-summary?page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return reportsAxiosInstance.get(url);
  };

  const getNIFundReceivedApi = (
    page: number,
    limit: number,
    sort: number,
    fromDate: string,
    toDate: string,
    filterQuery: string,
    type: String
  ) => {
    let url = `/money-management/finops/ni-daily-summary?page=${page}&limit=${limit}`;

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }

    if (sort) {
      url += `&sort=${sort}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    if (type) {
      url += `&type=${type}`;
    }

    return reportsAxiosInstance.get(url);
  };

  const postCommentsByIdApi = (
    threadId: number | string,
    params: any
  ) => {
    let url = `/reporting/${threadId}/comments`;

    return reportsAxiosInstance.post(url, params);
  };

  const getCommentsByThreadIdApi = (
    threadId: number,
    threadType: string
  ) => {
    let url = `/reporting/${threadId}/comments/${threadType}`;

    return reportsAxiosInstance.get(url);
  };

  const postCommentsByThreadIdApi = (
    threadId: number,
    params: any
  ) => {
    let url = `/reporting/${threadId}/comments`;

    return reportsAxiosInstance.post(url, params);
  };

  const getCommentsByIdApi = (
    threadId: number | string,
    threadType: string
  ) => {
    let url = `/reporting/${threadId}/comments/${threadType}`;

    return reportsAxiosInstance.get(url);
  };

  const getBrandsApi = () => {
    let url = `/fred-service/role/brand`;

    return fredAxiosInstance.get(url);
  };

  const getRolesByBrandApi = (
    brandId: string | number
  ) => {
    let url = `/fred-service/role/brand/${brandId}/roles`;

    return fredAxiosInstance.get(url);
  };

  const postRoleByBrandApi = (
    brandId: string | number,
    params: any
  ) => {
    let url = `/fred-service/role/brand/${brandId}/roles`;

    return fredAxiosInstance.post(url, params);
  };
  
  const postActivityLogApi = (
    params: any
  ) => {
    let url = `/fred-service/clients/activity-logs/insert`;

    return fredAxiosInstance.post(url, params);
  };

  const postTransactionStatusApi = ( params: any) =>{
    let url =`/money-management/transactions`
    return fredAxiosInstance.post(url, params);
  }

  const getMembersByBrandApi = (
    brandId: string | number
  ) => {
    let url = `/fred-service/role/brand/${brandId}/members`;

    return fredAxiosInstance.get(url);
  };

  const getPermissionsByRoleApi = (
    role: string
  ) => {
    let url = `/fred-service/role/name/${role}/role_permission`;

    return fredAxiosInstance.get(url);
  };

  const getPermissionsByRoleIdApi = (
    roleId: string | number
  ) => {
    let url = `/fred-service/role/roles/${roleId}/role_permission`;

    return fredAxiosInstance.get(url);
  };

  const updatePermissionsByRoleIdApi = (
    roleId: string | number,
    params: any
  ) => {
    let url = `/fred-service/role/roles/${roleId}/role_permission`;

    return fredAxiosInstance.post(url, params);
  };

  const updateRoleByRoleIdApi = (
    roleId: string | number,
    params: any
  ) => {
    let url = `/fred-service/role/roles/${roleId}/update`;

    return fredAxiosInstance.put(url, params);
  };

  const updateRoleForMemberApi = (
    params: any
  ) => {
    let url = `/fred-service/role/member/update/role`;

    return fredAxiosInstance.put(url, params);
  };

  const postMemberByBrandApi = (
    params: any
  ) => {
    let url = `/fred-service/role/users/onboard`;

    return fredAxiosInstance.post(url, params);
  };
  
  const getCountriesApi = (
    page: number,
    limit: number
  ) => {
    let url = `/onboarding/countries?limit=${limit}`;

    if (page) {
      url += `&page=${page}`
    }

    return onboardingAxiosInstance.get(url);
  };

  const getEmploymentIndustriesApi = (
    page: number,
    limit: number
  ) => {
    let url = `/master-data/industries?page=${page}&limit=${limit}`;

    return masterAxiosInstance.get(url);
  };

  const getEmploymentPositionsApi = (
    page: number,
    limit: number
  ) => {
    let url = `/master-data/positions?page=${page}&limit=${limit}`;

    return masterAxiosInstance.get(url);
  };

  const getNotificationsUnreadCountApi = () => {
    let url = `/fred-service/notifications/unread-count`;

    return fredAxiosInstance.get(url);
  };

  const getNotificationsApi = (
    page: number,
    limit?: number
  ) => {
    let url = `/fred-service/notifications?limit=${limit || 10}&page=${page}`;

    return fredAxiosInstance.get(url);
  };

  const getNotificationsSubscribeApi = (
    params: any
  ) => {
    let url = `/fred-service/notifications/subscribe`;

    return fredAxiosInstance.post(url, params);
  };

  const readNotificationApi = (
    id: string,
    params: any
  ) => {
    let url = `/fred-service/notifications/${id}/read`;

    return fredAxiosInstance.put(url, params);
  };

  const updateManualReviewStatusApi = (
    customerBankAccountID: number,
    params: any
  ) => {
    let url = `/money-management/customer-bank-accounts/${customerBankAccountID}/status`;

    return moneyAxiosInstance.put(url, params);
  };

  const updateFinopsRowDataApi = (
    txnNumber: number,
    params: any
  ) => {
    let url = `/money-management/finops/transactions/${txnNumber}`;

    return moneyAxiosInstance.put(url, params);
  };

  const updatePaymentRowDataApi = (
    txnNumber: number,
    params: any
  ) => {
    let url = `/money-management/transactions/${txnNumber}`;

    return moneyAxiosInstance.put(url, params);
  };

  const getTransfersApi = (
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string,
    q: string
  ) => {
    let url = `/money-management/wallet/transfers?page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return moneyAxiosInstance.get(url);
  };

  const getTransfersDownloadApi = (
    q: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string
  ) => {
    let url = `/money-management/wallet/transfers/download`;

    if (q) {
      url += `&q=${q}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    url = url.replace(/&/, '?');

    return moneyAxiosInstance.get(url);
  };

  const getTransfersByClientIdApi = (
    clientId: string | number,
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string,
  ) => {
    let url = `/money-management/wallet/transfers?page=${page}&limit=${limit}`;

    if (clientId) {
      url += `&customerId=${clientId}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return moneyAxiosInstance.get(url);
  };

  const getInstrumentGroupsApi = () => {
    let url = `/master-data/instruments/groups`;

    return masterAxiosInstance.get(url);
  };

  const getFinopsTransfersApi = (
    page: number,
    limit: number,
    sort: number,
    sortBy: string,
    fromDate: string,
    toDate: string,
    statusUpdatedFromDate: string,
    statusUpdatedToDate: string,
    filterQuery: string,
    q: string
  ) => {
    let url = `/money-management/wallet/finops/transfers?page=${page}&limit=${limit}`;

    if (q) {
      url += `&q=${q}`;
    }

    if (sort && sortBy) {
      url += `&sort=${sort}&sortBy=${sortBy}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (statusUpdatedFromDate) {
      url += `&statusUpdatedFromDate=${statusUpdatedFromDate}`;
    }
    if (statusUpdatedToDate) {
      url += `&statusUpdatedToDate=${statusUpdatedToDate}`;
    }

    if (filterQuery) {
      url += `${filterQuery}`
    }

    return moneyAxiosInstance.get(url);
  };

  return {
    getClientsApi,
    getClientsDownloadApi,
    getClientProfileApi,
    updateClientProfileApi,
    getCountriesDownloadApi,
    getCountriesRestrictionApi,
    updateFeatureByCountryApi,
    getDashboardClientsApi,
    updateKycStatusApi,
    getClientKycStatusApi,
    getClientAccountDetailsApi,
    getClientAccountDetailsV2Api,
    getClientCashBalanceApi,
    getClientOrdersApi,
    getClientCashAdjustmentsApi,
    getHolidayListByExchangeApi,
    postHolidayForExchangeApi,
    updateHolidayForExchangeApi,
    deleteHolidayForExchangeApi,
    getInstrumentsApi,
    getStockFiltersApi,
    getOrdersApi,
    getTransactionsApi,
    getTransactionsDownloadApi,
    getGiftsApi,
    getFinOpsGiftsApi,
    getGiftsByClientApi,
    getGiftsDownloadApi,
    updateGiftsStatusApi,
    getTransactionsByClientIdApi,
    getFinOpsTransactionsApi,
    updateTransactionsStatusApi,
    updateSingleTransactionStatusApi,
    getTransactionsCountByBatchIdApi,
    getUpdateInprogressTxnsApi,
    getWithdrawalReportsApi,
    getInstrumentDetailsApi,
    updateInstrumentDetailsApi,
    updateCfdInstrumentDetailsApi,
    getRefinitivInfoApi,
    getReportsApi,
    getReportDownloadApi,
    getWalletReportsApi,
    getWalletSnapshotApi,
    getWalletSnapshotDownloadApi,
    getWalletReportDownloadApi,
    getReportsListApi,
    getSettlementsApi,
    updateSettlementsByIdApi,
    getSettlementsListApi,
    updateNIFundSettlementsByIdApi,
    getSettlementsDownloadApi,
    updateSettlementStatusByIdApi,
    updateWTSettlementStatusByIdApi,
    getTransactionHistoryListApi,
    updateNIFundSettlementStatusByIdApi,
    getDWTransactionsApi,
    getWalletTransactionsApi,
    getNIFundReceivedApi,
    postCommentsByIdApi,
    getCommentsByIdApi,
    getCommentsByThreadIdApi,
    postCommentsByThreadIdApi,
    getBrandsApi,
    getRolesByBrandApi,
    getMembersByBrandApi,
    getPermissionsByRoleApi,
    postRoleByBrandApi,
    getPermissionsByRoleIdApi,
    updatePermissionsByRoleIdApi,
    updateRoleByRoleIdApi,
    updateRoleForMemberApi,
    postMemberByBrandApi,
    getCountriesApi,
    postActivityLogApi,
    postTransactionStatusApi,
    getEmploymentIndustriesApi,
    getEmploymentPositionsApi,
    getNotificationsUnreadCountApi,
    getNotificationsApi,
    getNotificationsSubscribeApi,
    readNotificationApi,
    updateManualReviewStatusApi,
    updateFinopsRowDataApi,
    updatePaymentRowDataApi,
    getTransfersApi,
    getTransfersDownloadApi,
    getTransfersByClientIdApi,
    getFinopsTransfersApi,
    getInstrumentGroupsApi,
  };
};

export default useRequests;
