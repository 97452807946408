
export const TransactionHistoryFilters = [
  {
    parentKey: 'status',
    title: 'Status',
    filters: [
      {
        key: 'NORMAL',
        label: 'Normal',
        isChecked: false
      },
      {
        key: 'RESOLVED',
        label: 'Resolved',
        isChecked: false
      },
      {
        key: 'DISCREPANCY',
        label: 'Discrepancy',
        isChecked: false
      }
    ]
  }
];