import Icon from './Icon';

const Navigation = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg
        width="21"
        height="26"
        viewBox="0 0 21 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.76102 11.0827C4.76102 7.9128 7.33072 5.3431 10.5006 5.3431C13.6705 5.3431 16.2402 7.9128 16.2402 11.0827C16.2402 14.2526 13.6705 16.8223 10.5006 16.8223C7.33072 16.8223 4.76102 14.2526 4.76102 11.0827ZM10.5006 7.1556C8.33173 7.1556 6.57352 8.91381 6.57352 11.0827C6.57352 13.2516 8.33173 15.0098 10.5006 15.0098C12.6695 15.0098 14.4277 13.2516 14.4277 11.0827C14.4277 8.91381 12.6695 7.1556 10.5006 7.1556Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.259206 9.70138C0.687351 4.50722 5.02787 0.509766 10.2396 0.509766H10.7616C15.9733 0.509766 20.3138 4.50722 20.742 9.70138C20.972 12.4916 20.1101 15.2622 18.3379 17.4295L12.5463 24.5125C11.489 25.8056 9.51222 25.8056 8.45492 24.5125L2.66331 17.4295C0.891094 15.2622 0.0292126 12.4916 0.259206 9.70138ZM10.2396 2.32227C5.97115 2.32227 2.41623 5.59622 2.06558 9.85028C1.87416 12.1725 2.59148 14.4784 4.06645 16.2822L9.85806 23.3652C10.1902 23.7714 10.811 23.7714 11.1431 23.3652L16.9348 16.2822C18.4097 14.4784 19.127 12.1725 18.9356 9.85028C18.585 5.59622 15.0301 2.32227 10.7616 2.32227H10.2396Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};

export default Navigation;