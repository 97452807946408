import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemIcon, Drawer, Tooltip } from '@mui/material';
import _ from 'lodash';
import LogoInitial from './icons/LogoInitial';
import User from './icons/User';
import Exchange from './icons/Exchange';
import ChartPie from './icons/ChartPie';
import ClipboardAlt from './icons/ClipboardAlt';
import CalendarMenu from './icons/CalendarMenu';
import Orders from './icons/Orders';
import Navigation from './icons/Navigation';
import { GlobalContext } from 'context';

const listItems = [
  {
    resourceName: "DASHBOARD",
    icon: <LogoInitial />,
    label: "Dashboard",
    to: "/",
    isLogo: true,
    toolTipSx: { left: "-1px" },
  },
  {
    resourceName: "CLIENTS",
    icon: <User />,
    label: "Clients",
    to: "/clients",
    subpaths: ["/profile"],
  },
  {
    resourceName: "PAYMENTS",
    icon: <Exchange />,
    label: "Payments",
    to: "/transactions",
  },
  {
    resourceName: "MARKETS",
    icon: <ChartPie />,
    label: "Markets",
    to: "/markets",
    subpaths: ["/market/profile", "/market/cfd/profile"],
    toolTipSx: { left: "-8px" },
  },
  {
    resourceName: "ORDERS",
    icon: <Orders sx={{ left: 3 }} />,
    label: "Orders",
    to: "/orders",
    toolTipSx: { left: "-3px" },
  },
  {
    resourceName: "REPORTS",
    icon: <ClipboardAlt />,
    label: "Reports",
    to: "/reports",
    subpaths: ["/wallet-snapshot"],
  },
  {
    resourceName: "EXCHANGE_HOLIDAY",
    icon: <CalendarMenu />,
    label: "Exchange",
    to: "/exchange/holidays",
    toolTipSx: { left: "-9px" },
  },
  {
    resourceName: "COUNTRIES",
    icon: <Navigation />,
    label: "Countries",
    to: "/countries",
    toolTipSx: { left: "-5px" },
    iconSx: { marginLeft: .575 },
  },
];

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  minHeight: 37,
  justifyContent: 'center',
  paddingRight: 25,
  paddingLeft: 20,
  display: 'inline-flex',
  marginTop: 30,
  '&.icon-logo': {
    marginTop: 4,
    paddingRight: 13
  },
  '& .icon svg': {
    color: theme.palette.text.secondary
  },
  '&:hover .icon svg': {
    color: theme.palette.success.light
  },
  '&[aria-current=page] .icon svg, &.active .icon svg': {
    color: theme.palette.success.light
  }
}));

const drawerWidth = 77;

const StyledDrawer = styled(Drawer)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.primary.main
    }
  })
);

const CssDrawer = ({...rest}) => {
  const {
    setDateRange,
    setSearchQuery,
    setIsSearchClosed,
    setFilterDates,
    setPaymentFilterDates,
    setIsFilterApplied,
    setIsFilterCleared,
    setEnableSearchField,
    setDashboardSelectedFilter,
    setTransactionsStatusFilters,
    setFinopsTransactionsStatusFilters,
    setFinopsTransfersStatusFilters,
    setGiftsStatusFilters,
    setLegalEntity,
    setIsUpdatePage,
    setIndeterminateCheck,
    setReportBatchIds,
    setIsReportBatchIdDeleted,
    setIsReportFilterApplied,
    setIsRowAdd,
    setCheckboxList,
    setSelectedAllChecks,
    setIsChangeReportBatchIds,
    setIsWithDrawalTabSelected,
    setTransactionsDownloadData,
    setIsFilterIconDisplay,
    setEnableSelectAllCheckbox,
    setUncheckedTxnIds,
    setCheckedTxnIds,
    setFifoReportBatchId,
    setInstrumentsProfileDetails,
    setInstrumentsCfdProfileDetails,
    setIsSelectAllCheckboxClicked,
    setMostAppearedTransactionStatus,
    setCheckedTransactionStatus,
    setDefaultFilterDates,
    setDateSelectedFilter,
    setClientFilterQuery,
    setIsPermissionGranted,
    userPermissions,
    setAutocompleteIds,
    setIsAutocompleteIdDeleted,
    setIsAutocompleteFilterApplied,
    setIsChangeAutocompleteIds,
    setClientProfileDetails,
    setClientProfileInfo,
    setNotificationFilterQuery,
    setReportTabValue,
    setHighlightNotificationRecords,
    setRecordCount,
    setSelectedButtonType,
    setSelectedTabType,
    setTransfersStatusFilters,
    setTransactionFilterQuery,
    setIsWalletTabSelected,
    setWalletReportDownloadData,
    setCountriesFilterQuery,
    setCountriesStatusFilters,
  } = useContext(GlobalContext);
  const location = useLocation();
  const [activePage, setActivePage] = useState<any>(null);

  const handleNavLinkClick = (resourceName: string) => {
    setDateRange('');
    setSearchQuery('');
    setIsSearchClosed(true);
    setFilterDates({ fromDate: '', toDate: ''});
    setPaymentFilterDates({ fromDate: '', toDate: '', statusUpdatedFromDate: '',
    statusUpdatedToDate: '' });
    setIsFilterApplied(false);
    setIsFilterCleared(false);
    setTransactionFilterQuery('');
    setEnableSearchField(false);
    setDashboardSelectedFilter('DASHBOARD_COMP');
    setTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setFinopsTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setGiftsStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setLegalEntity(null);
    setIsUpdatePage(false);
    setIndeterminateCheck(false);
    setReportBatchIds([]);
    setIsReportBatchIdDeleted(false);
    setIsReportFilterApplied(false);
    setIsRowAdd(false);
    setCheckboxList(null);
    setSelectedAllChecks(false);
    setDefaultFilterDates({ fromDate: '', toDate: ''});
    setIsChangeReportBatchIds(false);
    setIsWithDrawalTabSelected(true);
    setTransactionsDownloadData(null);
    setDateSelectedFilter('all');
    setIsFilterIconDisplay(false);
    setEnableSelectAllCheckbox(false);
    setUncheckedTxnIds([]);
    setCheckedTxnIds([]);
    setFifoReportBatchId('');
    setInstrumentsProfileDetails((ipds: any) => _.map(ipds, (i) => {
      if (i.value) {
        i.value = '';
      } else if (i.isEdit === true) {
        i.isEdit = false;
      } else if (i.section && i.fields) {
        i.fields = i.fields.map((f: any, i: number) => {
          if (f?.value) {
            f.value = '';
          } else if (f?.isEdit === true) {
            f.isEdit = false;
          }
          return f;
        });
      }
      return i;
    }));
    setInstrumentsCfdProfileDetails((ipds: any) => _.map(ipds, (i) => {
      if (i.value) {
        i.value = '';
      } else if (i.isEdit === true) {
        i.isEdit = false;
      } else if (i.section && i.fields) {
        i.fields = i.fields.map((f: any, i: number) => {
          if (f?.value) {
            f.value = '';
          } else if (f?.isEdit === true) {
            f.isEdit = false;
          }
          return f;
        });
      }
      return i;
    }));
    setIsSelectAllCheckboxClicked(false);
    setMostAppearedTransactionStatus('');
    setCheckedTransactionStatus('');
    setIsUpdatePage(false);
    setDateSelectedFilter('all');
    setClientFilterQuery('');

    if (
      (resourceName !== "DASHBOARD" && resourceName !== "COUNTRIES") &&
      userPermissions.length &&
      !_.find(userPermissions, (up) => up.resourceName === resourceName)
        ?.isVisible
    ) {
      setIsPermissionGranted(true);
    } else {
      setIsPermissionGranted(false);
    }
    
    setAutocompleteIds([]);
    setIsAutocompleteIdDeleted(false);
    setIsAutocompleteFilterApplied(false);
    setIsChangeAutocompleteIds(false);
    setClientProfileDetails((cpds: any) => _.map(cpds, (i) => {
      if (i.value) {
        i.value = '';
      } else if (i.section && i.fields) {
        i.fields = i.fields.map((f: any, i: number) => {
          if (f?.value) {
            f.value = '';
          }
          return f;
        });
      } else if (i.rootField) {
        i.rootField.value = '';
      } else if (i.section === 'pep') {
        i.renderByField = [];
      }
      return i;
    }));
    setClientProfileInfo(null);
    setRecordCount(null);
    setNotificationFilterQuery('');
    setReportTabValue(null);
    setHighlightNotificationRecords(false);
    setSelectedButtonType('');
    setSelectedTabType('');

    setTransfersStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        f.isChecked = false;
        if (c.parentKey === 'status') {
          f.isDisabled = true;
        } else {
          f.isDisabled = false;
        }
        return f;
      });
      return c;
    }));

    setFinopsTransfersStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        f.isChecked = false;
        if (c.parentKey === 'status') {
          f.isDisabled = true;
        } else {
          f.isDisabled = false;
        }
        return f;
      });
      return c;
    }));

    setIsWalletTabSelected(false);
    setWalletReportDownloadData(null);

    setCountriesFilterQuery('');
    setCountriesStatusFilters((csf: any) =>
      _.map(csf, (c) => {
        c.filters = _.map(c.filters, (f: any) => {
          if (f.isChecked) {
            f.isChecked = false;
          }
          return f;
        });
        return c;
      })
    );
  };

  useEffect(() => {
    setActivePage(_.find(listItems, (l) => {
      if (l.subpaths && l.subpaths.includes(location.pathname)) {
        return l;
      }
    }));
  }, [location]);

  return (
    <StyledDrawer {...rest}>
      <List>
        {listItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <Tooltip
              title={item.label}
              placement={"right"}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    left: "-10px",
                    position: "relative",
                    ...(item.toolTipSx && item.toolTipSx),
                  },
                },
              }}
            >
              <StyledNavLink
                to={item.to}
                className={
                  item.isLogo
                    ? "icon-logo"
                    : activePage && activePage.to === item.to
                    ? "active"
                    : ""
                }
                onClick={() => {
                  handleNavLinkClick(item.resourceName);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: "auto",
                    justifyContent: "center",
                    ...(item.iconSx && item.iconSx),
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </StyledNavLink>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </StyledDrawer>
  );
};

export default CssDrawer;